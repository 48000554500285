<template>
  <v-container>

    <v-card
      class="mx-auto mt-3"
      max-width="800"
      elevation="3"
    >
      <v-card-text>

        <!-- Info Banner -->
        <v-row class="px-10 py-0">

          <v-img src="@/assets/logoGalpon.png" max-width="120px" class="mr-3" />

          <v-col class="titleBck">
            <div class="mt-5">Galpón Cultural UCN Coquimbo</div>
            <p class="text-h4 text--primary">
              Inscripción de Talleres
            </p>
          </v-col>

        </v-row>

        
        <!-- Decoration -->
        <v-row class="mb-6">
          <v-col cols="12">
            <v-card class="titleUnderline" min-height="12px" elevation="1" />
          </v-col>
        </v-row>


        <!-- Content -->
        <div v-if="!isLoadingRegProcess || !isLoading">
          <div v-if="validProcess">

            <p class="text-body-1">Bienvenid@, para inscribirse en los talleres del Galpón Cultural, por favor complete los siguientes datos</p>
            

            <v-stepper
              v-model="stepValue"
              vertical
            >
              <!-- Personal data -->
              <v-stepper-step
                :complete="stepValue > 1"
                step="1"
              >
                <strong>Paso 1:</strong> Datos Personales
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-form
                  ref="form"
                  v-model="validForm"
                  lazy-validation
                >
                  <v-row class="py-2">
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.rut"
                        label="RUT"
                        hint="Ingrese su RUT. Guión y puntos son opcionales. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        :rules="rutRules"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.emailUCN"
                        label="Correo UCN"
                        hint="Ingrese su correo terminado en @ucn.cl . Campo obligatorio"
                        persistent-hint
                        outlined dense
                        :rules="emailRules"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="reg.studentInfo.fullname"
                        label="Nombre Completo"
                        hint="Ingrese su nombre completo. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        :rules="nameRules"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        v-model="reg.studentInfo.career"
                        :items="careers"
                        label="Carrera"
                        hint="Seleccione su carrera. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.phone"
                        label="Teléfono"
                        hint="Ingrese su número telefónico. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.address"
                        label="Dirección"
                        hint="Ingrese su dirección. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.lesion"
                        label="Lesiones (opcional)"
                        outlined dense
                      />
                    </v-col>
                  </v-row>
                </v-form>

                <v-btn
                  color="primary"
                  @click="goStep2()"
                  class="mt-3"
                  :disabled="!validForm"
                >
                  Continuar
                </v-btn>
              </v-stepper-content>

              <!-- Course selection -->
              <v-stepper-step
                :complete="stepValue > 2"
                step="2"
              >
                <strong>Paso 2:</strong> Seleccionar Talleres
              </v-stepper-step>

              <v-stepper-content step="2">

                <v-row class="py-2">
                  <v-col cols="12">
                    <v-select
                      v-model="reg.courses"
                      :items="registrationProcess.courses"
                      item-text="name"
                      item-value="_id"
                      label="Seleccione uno o más talleres"
                      multiple
                      chips
                      hint="Selecciones los talleres que desea inscribirse"
                      persistent-hint
                      outlined
                    >
                      <v-icon slot="prepend">mdi-school</v-icon>
                    </v-select>
                  </v-col>
                </v-row>

                <div class="mt-5">
                  <v-btn @click="stepValue = 1" text>
                    Volver
                  </v-btn>
                  <v-btn
                    color="success"
                    @click="submitRegistration()"
                    :disabled="!validSelectedCourses"
                  >
                    Confirmar y enviar
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper>

          </div>

          <!-- Not active reg process alert -->
          <div v-else>
            <v-alert prominent type="error" >
              <v-row align="center">
                <v-col>
                  Disculpe, el proceso de inscripción no está activo en estos momentos.
                </v-col>
              </v-row>
            </v-alert>
          </div>
        </div>

        <div v-else>
          <LoadingIndicator />
        </div>

      </v-card-text>
    </v-card>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </v-container>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import { getDDMMYYYY } from '@/libs/dateHelpers'
import { getCareers } from '@/libs/careers'
import Validation from '@/libs/validation'

export default {
  name: `Registration`,
  components: {
    LoadingIndicator,
  },
  computed: {
    validSelectedCourses() {
      return this.reg.courses.length > 0
    },
    validProcess() {
      if(this.inRegistrationProcess && this.validRange) {
        return true
      } else {
        return false
      }
    }
  },
  data: () => ({
    //state
    isLoading: true,
    isLoadingRegProcess: true,
    isSaving: false,
    isSnackbar: false,
    snackcolor: 'success',
    snacktext: '',
    stepValue: 1,
    validForm: false,
    //validation
    nameRules: [ v => !!v || 'Este campo es requerido' ],
    rutRules: [
      v => Validation.verifyRUT(v) || 'El rut es inválido'
    ],
    emailRules: [
      v => !!v || 'Un e-mail es requerido',
      v => Validation.email(v) || 'El rut es inválido'
    ],
    //data
    inRegistrationProcess: false,
    validRange: false,
    registrationProcess: {
      _id: null,
      name: null,
      startDate: null,
      endDate: null,
      courses: [],
      totalRegs: 0,
    },
    reg: {
      studentInfo: {
        rut: null,
        fullname: null,
        emailUCN: null,
        career: null,
        phone: null,
        address: null,
        lesion: null,
      },
      courses: [],
    },
    careers: getCareers(),
  }),
  methods: {
    async loadInRegistration() {
      try {

        this.isLoadingRegProcess = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/settings`)
        this.inRegistrationProcess = response.data.data.inRegistrationProcess || false

      } catch (e) {
        console.log(e)
      } finally {
        this.isLoadingRegProcess = false
      }
    },
    async loadLastRegistration() {
      try {
      
        this.isLoading = true
      
        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/registration`)

        if(!resp.data.data) {
          this.isLoading = false
          return
        }

        //set valid date range to check if can register
        const timenow = new Date()
        const startDate = new Date(resp.data.data.startDate.replace('Z', ''))
        const endDate = new Date(resp.data.data.endDate.replace('Z', ''))

        this.validRange = startDate <= timenow && timenow <= endDate

        this.registrationProcess = {
          name: resp.data.data.name,
          startDate: getDDMMYYYY(startDate),
          endDate: getDDMMYYYY(endDate),
          courses: resp.data.data.courses,
          totalRegs: resp.data.data.courses.reduce((acc, course) => acc + course.students.length, 0),
        }
      
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async submitRegistration() {
      try {

        this.isSaving = true

        await this.axios.post(`${process.env.VUE_APP_APIURL}/registration/signup`, this.reg)

        this.snackcolor = 'success'
        this.snacktext = 'Se registró el alumno correctamente'
        this.isSnackbar = true

      } catch (e) {
        console.error(e)
        this.snackcolor = 'error'
        this.snacktext = 'Se produjo un error al registrar el alumno'
        this.isSnackbar = true
      } finally {
        this.isSaving = false
      }
    },
    goStep2() {
      this.stepValue = (this.$refs.form.validate()) ? 2 : 1
    }
  },
  mounted() {
    Promise.all([
      this.loadLastRegistration(),
      this.loadInRegistration(),
    ])
  }
}
</script>

<style lang="scss" scoped>

.titleBck {

  color: #444;

  & h1 {
    font-size: 1.6rem;
  }

  & h2 {
    font-size: 1.2rem;
  }
}

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, rgb(243, 115, 53) 25%, #f5af19 10%);
  border-radius: 3px 3px 70px 3px;
}
</style>
