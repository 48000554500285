export default {

  required: value => !!value || 'Requerido.',

  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'E-mail inválido.'
  },

  verifyRUT: (rut) => {

    if(!rut) {
      return false
    }

    rut = rut.replace(/[.-]/g, "")
    rut = rut.toUpperCase()

    let patt = /^\d{1,8}[0-9K]$/
    let ok = patt.test(rut)
    let cStr = rut.slice(0, -1)
    let cDig = rut.slice(-1)
    let nSum = 0;
    let nVal = 0;
    let cVal = ""

    if(ok) {
       for(let nMul = 2; cStr != ""; nMul = (nMul == 7) ? 2 : nMul + 1) {
            nSum += Number(cStr.slice(-1)) * nMul
            cStr = cStr.slice(0, -1)
       }

       nVal = 11 - (nSum % 11)

       switch(nVal) {
          case 11:
            cVal = "0";
            break
          case 10:
            cVal = "K";
            break
          default:
            cVal = nVal.toString()
       }

       ok = cVal == cDig

    }

    return ok

  }

}