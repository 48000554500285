// Careers enum
export function getCareers() {
  return [
    "Biología Marina",
    "Derecho",
    "Enfermería",
    "Ing. Civil en Computación e Informática",
    "Ing. Civil Industrial",
    "Ing. Comercial",
    "Ing. en Acuicultura",
    "Ing. en Información y Control de Gestión",
    "Ing. en Prevención de Riesgos y Medioambiente",
    "Ing. en Tecnologías de Información",
    "Kinesilogía",
    "Licenciatura en Ciencias Religiosas",
    "Medicina",
    "Nutrición y Dietética",
    "Pedagogía en Educación Diferencial con Mención en Desarrollo Emocional y Cognitivo",
    "Pedagogía en Filosofía y Religión"
  ]
}